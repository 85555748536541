@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
  scroll-behavior: auto;
}

.no-scroll {
    height: 100%;
    overflow: hidden;
}

/* Custom styles for overlaying slick controls with Tailwind */
/* index.css or another global stylesheet */
.slick-dots {
  position: absolute !important;
  bottom: 25px !important;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 20 !important;
}

.slick-arrow {
  z-index: 20 !important;
}

.slick-prev, .slick-next {
  background: rgba(0, 0, 0, 0.5) !important; /* Semi-transparent background for visibility */
  color: white !important; /* Arrow color */
  border-radius: 9999px; /* Fully rounded corners for a circle shape */
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

.slick-dots li {
  margin: 0; /* Reduce space between dots. Adjust as needed. */
}

.slick-dots li button:before {
  /* This targets the default dots */
  color: rgb(255, 255, 255);
  opacity: 0.7;
  margin: 0;
}

.slick-dots li.slick-active button:before {
  /* This targets the active dot */
  color: rgb(255, 255, 255);
  opacity: 1.0;
  font-size: 8px !important; /* Makes the active dot larger */
}

/* Optional: If you want to change the size of all dots, not just the active one */
.slick-dots li button:before {
  color: rgb(255, 255, 255);
  opacity: 0.6;
  font-size: 8px !important; /* Adjust size as needed */
}
